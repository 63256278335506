<template>
	<div>
		<!-- banner part start-->
        <section class="banner_part">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-xl-6">
                        <div class="banner_text">
                            <div class="banner_text_iner">
                                <span class="mb-4"><i class="mdi mdi-pen"></i> {{ $j('WelcomeSite') }}</span>
                                <h1>{{ $j('SiteTitle') }}</h1>
                                <p>{{ $j('SiteDescription') }}</p>
                                <router-link to="/service/deposition" class="btn_main">{{ $j('Deposition') }}</router-link>
                                <router-link to="/about/leadership" class="btn_secondary ml-3">{{ $j('AboutAssociation') }}</router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-xl-6 movie-banner">
                        <div class="rectangle"></div>
                        <div class="mask-group"></div>
                        <span class="top-icon">
                            <i class="mdi mdi-camcorder"></i>
                        </span>
                        <span class="bottom-icon">
                            <i class="mdi mdi-pencil"></i>
                        </span>
                    </div>
                </div>
            </div>
        </section>
        <!-- banner part start-->

        <!-- Our Partners Area -->
        <section class="our_partners_area" style="background: #111B30; padding: 15px 0;">
            <div class="containers">
                <h2 class="text-center text-white mt-3 text-uppercase">{{ $j('OurPartner') }}</h2>
                <div class="partners">
                    <div class="item"><img src="/assets/img/partners/1.png" alt=""></div>
                    <div class="item"><img src="/assets/img/partners/2.png" alt=""></div>
                    <div class="item"><img src="/assets/img/partners/3.png" alt=""></div>
                    <div class="item"><img src="/assets/img/partners/4.png" alt=""></div>
                    <div class="item"><img src="/assets/img/partners/5.png" alt=""></div>
                </div>
            </div>
        </section>
        <!-- End Our Partners Area -->

        <!-- about part start-->
        <section class="about_part mt-2">
            <div class="container">
                <div class="section_tittle d-flex justify-content-center mb-5">
                    <h2 class="corner-border">{{ $j('AboutAssociation') }} {{ $j('GeneralInformation') }}</h2>
                </div>
                <div class="row">
                    <div class="col-md-6 about-border about-border-left">
                        <div class="about_text">
                            <h2 class="mb-3">{{ $j('AuthorsUnion') }}</h2>
                            <p>{{ $j('AuthorsUnionText1') }}</p>
                            <p>{{ $j('AuthorsUnionText2') }}</p>
                            <p>{{ $j('AuthorsUnionText3') }}</p>
                            <router-link to="/page/about-association" class="btn_3 mt-4">
                                <i class="fa fa-sign-in-alt"></i> {{ $j('More') }} <i class="mdi mdi-arrow-right"></i>
                            </router-link>
                        </div>
                    </div>
                    <div class="col-md-6 about-border about-border-right">
                        <div class="about_text">
                            <img src="/assets/img/about/19.png" class="img-fluid">
                            <div class="row">
                                <div class="stat">
                                    <h2>20 +</h2>
                                    <p>{{ $j('YearsOfExperience') }}</p>
                                </div>
                                <div class="stat">
                                    <h2>100 +</h2>
                                    <p>{{ $j('Employees') }}</p>
                                </div>
                                <div class="stat">
                                    <h2>40 +</h2>
                                    <p>{{ $j('Partners') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- about part start-->
        
        <section class="blog_part section_padding gray_bg">
            <div class="container">
                <div class="section_tittle d-flex justify-content-between align-items-center">
                    <h2 class="corner-border">{{ $j('News') }}</h2>
                    <a href="/news/index" class="btn_3">
                        <i class="fa fa-sign-in-alt"></i> {{ $j('More') }} <i class="mdi mdi-arrow-right"></i>
                    </a>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-lg-3 col-xl-3" v-for="(item, value) in news" :key="value">
                        <div class="single-home-blog">
                            <div class="card">
                                <img :src="$store.state.mainBaseUrl + item.filePath" class="card-img-top" alt="">
                                <div class="card-body">
                                    <!-- <a href="blog.html">Technology</a> | <span> March 30, 2019</span> -->
                                    <a :href="`/news/detail/` + item.id"><h5 class="card-title">{{ item.titleUz }}</h5></a>
                                    <ul class="d-flex justify-content-between">
                                        <li> <span class="ti-calendar"></span>{{ parseDate(item.createdDate) }}</li>
                                        <li> <span class="ti-eye"></span>{{ item.readCount }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- service_part start-->
        <section class="team section_padding">
            <div class="container">
                <div class="section_tittle d-flex justify-content-between align-items-center">
                    <h2 class="corner-border">{{ $j('Management') }}</h2>
                    <a href="/about/leadership" class="btn_3">
                        <i class="fa fa-sign-in-alt"></i> {{ $j('More') }} <i class="mdi mdi-arrow-right"></i>
                    </a>
                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-6 mb-4" v-for="(item, value) in staffs" :key="value">
                        <div class="member">
                            <div class="pic"><img :src="$store.state.mainBaseUrl + item.photoPath" class="img-fluid" alt=""></div>
                            <div class="member-info">
                                <h4>{{ item.firstName }} {{ item.middleName }}</h4>
                                <span>{{ item.position }}</span>
                                <div class="social">
                                    <a href="tel: +998991009919">
                                        <i class="mdi mdi-phone"></i> +998991234567
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Photo Silder start -->
        <section class="media_part section_padding">
            <div class="section_tittle d-flex justify-content-center">
                <h2 class="corner-border">{{ $j('PhotoMaterials') }}</h2>
            </div>
            <div class="carousel-gallery">
                <div class="swiper-container">
                    <div class="swiper-wrapper">
                        <!-- <div class="swiper-slide">
                            <a href="/assets/img/gallery/13.jpg" data-fancybox="gallery">
                                <img class="image" src="/assets/img/gallery/13.jpg">
                            </a>
                        </div> -->
                        <div class="swiper-slide">
                            <a href="/assets/img/gallery/13.jpg" data-fancybox="gallery">
                                <div class="image" style="background-image: url(/assets/img/gallery/13.jpg)">
                                    <div class="overlay">
                                        <em class="mdi mdi-arrow-expand-all"></em>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="swiper-slide">
                            <a href="/assets/img/gallery/14.jpg" data-fancybox="gallery">
                                <div class="image" style="background-image: url(/assets/img/gallery/14.jpg)">
                                    <div class="overlay">
                                        <em class="mdi mdi-arrow-expand-all"></em>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="swiper-slide">
                            <a href="/assets/img/gallery/15.jpg" data-fancybox="gallery">
                                <div class="image" style="background-image: url(/assets/img/gallery/15.jpg)">
                                    <div class="overlay">
                                        <em class="mdi mdi-arrow-expand-all"></em>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="swiper-slide">
                            <a href="/assets/img/gallery/16.jpg" data-fancybox="gallery">
                                <div class="image" style="background-image: url(/assets/img/gallery/16.jpg)">
                                    <div class="overlay">
                                        <em class="mdi mdi-arrow-expand-all"></em>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center">
                <router-link to="#" class="btn_6 mt-4 mb-4">
                    <i class="fa fa-sign-in-alt"></i> {{ $j('All') }} <i class="mdi mdi-arrow-right"></i>
                </router-link>
            </div>
        </section>
	</div>
</template>

<script>

//import owlWrapper from "./components/banner/owlWrapper";
export default {
	components: {
		//owlWrapper
	},
	data() {
		return {
            news: [],
            staffs: [],
        };
	},
    methods: {
		checkUrl(){
			if (window.location.href.indexOf("userServiceOneIdIntegrate") > 0) {
				let link = window.location.href.split("=");
				let code = link[1].split("&")[0];
				loginByOneId(code);
			}
		},
        partnersCarosel(){
            if ( $('.partners').length ){
                $('.partners').owlCarousel({
                    loop:true,
                    items:5,
                    //margin:110,
                    autoplay:true,
                    response:true,
                    responsive:{
                        300:{
                            items:2, 
                            margin:0,
                        },
                        500:{
                            items:3, 
                        },
                        700:{
                            items:3, 
                        },
                        800:{
                            items:4,
                            margin:40,
                        },
                        1000:{
                            items:5,
                        },  
                    }
                });
            };
        },
        gallerySwiper(){
            if ( $('.carousel-gallery').length ){
                new Swiper('.carousel-gallery .swiper-container', {
                    effect: 'slide',
                    loop: true,
                    speed: 900,
                    slidesPerView: 4,
                    spaceBetween: 10,
                    simulateTouch: true,
                    autoplay: {
                        delay: 2000,
                        stopOnLastSlide: false,
                        disableOnInteraction: false
                    },
                    breakpoints: {
                        // when window width is <= 320px
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 5
                        },
                        // when window width is <= 480px
                        425: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        // when window width is <= 640px
                        768: {
                            slidesPerView: 3,
                            spaceBetween: 20
                        }
                    }
                });
            }
        },
        getNews() {
			this.$api.get("/query_service/api/v1/news/get_news?offset=0&limit=4")
            .then(response => {
				this.news = response.result.data;
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
        getStaff() {
			this.$api.get("/query_service/api/v1/stuff/get_stuff?offset=0&limit=4").then(response => {
				this.staffs = response.result.data;
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		},
		parseDate(date) {
			var parse = '0';
			if(date && date != 0){
				parse = moment(date).format('DD.MM.YYYY');
			}
			return parse;
		},
	},
	mounted(){
		this.checkUrl();
        this.partnersCarosel();
        this.gallerySwiper();
        this.getStaff();
        this.getNews();
	}
};
</script>